import { getCookie } from './cookie';

const SESSION_ID_KEY = 'session_id';
const CLIENT_SESSION_COOKIE_NAME = 'browser_client_id';
const KEYCLOAK_ACTIVITY_COOKIE_NAME = 'kc_activity_id';
const cookie_domain = window.__ES_COOKIE_DOMAIN;

function getSnowplowDuid() {
    const cookieName = '_sp_';
    const matcher = new RegExp(cookieName + 'id\\.[a-f0-9]+=([^;]+);?');
    const match = document.cookie.match(matcher);
    if (match && match[1]) {
        const split = match[1].split('.');
        return {
            domain_userid: split[0],
            domain_sessionidx: split[2],
            domain_sessionid: split[5],
        };
    } else {
        return false;
    }
}

function generateUUID() {
    // Public Domain/MIT
    let d = new Date().getTime(); //Timestamp
    let d2 = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = Math.random() * 16; //random number between 0 and 16
        if (d > 0) {
            //Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        } else {
            //Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
}

function sendSentryError(message) {
    if (window.Sentry) {
        Sentry.withScope(() => {
            // todo: do we need some additional data here?
            Sentry.captureException(new Error('Tracking error. ' + message));
        });
    }
}

export function getSessionId() {
    const params = new URLSearchParams(location.search);
    const session_id = params.get(SESSION_ID_KEY);
    const sp_tracking_info = getSnowplowDuid();
    var activity_id;
    if (session_id != null && session_id !== '') {
        // If session_id provided set it to keycloak tracking cookies
        // this is path that client provided specific activity_id for example mobile, plugins, web etc
        // we do not want to store this in our "client_session_id" cookie as they are persistent and would link mobile/web tracking
        activity_id = session_id;
    } else if (document.cookie.includes(KEYCLOAK_ACTIVITY_COOKIE_NAME)) {
        // If no new session_id is provided, but we have a keycloak tracking cookie set by keycloak use it!
        activity_id = getCookie(KEYCLOAK_ACTIVITY_COOKIE_NAME);
    } else if (document.cookie.includes(CLIENT_SESSION_COOKIE_NAME)) {
        // if no session_id if explicitly provided, but we have a client_session_id cookie set by fastly on the web use it!
        activity_id = getCookie(CLIENT_SESSION_COOKIE_NAME);
    } else if (sp_tracking_info && sp_tracking_info['domain_sessionid']) {
        sendSentryError('Using snowplow tracking cookie as activity_id');
        activity_id = sp_tracking_info['domain_sessionid'];
    } else {
        sendSentryError('Generating new uuid as activity_id');
        activity_id = generateUUID();
    }

    if (activity_id && getCookie(KEYCLOAK_ACTIVITY_COOKIE_NAME) !== activity_id) {
        // if we have a new activity_id set it in the keycloak tracking cookie
        document.cookie = `${KEYCLOAK_ACTIVITY_COOKIE_NAME}=${activity_id};domain=${cookie_domain};path=/;max-age=63072000;SameSite=Lax;Secure;`;
    }

    return getCookie(KEYCLOAK_ACTIVITY_COOKIE_NAME);
}
